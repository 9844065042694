import React, { ReactElement } from 'react';
import './Rules.scss';

const Et: React.FC = (): ReactElement => {
  return (
    <div className="Et rules-wrapper">
      <h2>Kampaania reeglid</h2>
      <ol>
        <li>
          Fazeri kampaania on{' '}
          <span className="bold">alates 01.04.2025 kuni 30.04.2025</span> toimuv
          tarbijamäng “VÕIDA IKEA 3000€ KINKEKAART” (edaspidi kampaania), mis
          leiab aset{' '}
          <span className="bold">
            kõigis Eesti Vabariigi territooriumil asuvates kauplustes
          </span>
          , kus kampaanias osalevaid tooteid müüakse.{' '}
        </li>
        <li>
          Kampaania ja auhinnad korraldab:{' '}
          <span className="bold">Fazer Eesti OÜ</span> (Reg. nr 10057691), Pärnu
          maantee 158/1, 11317 Tallinn, Kawe Ärikeskuse 5. korrus; telefon: +372
          650 2421; e-post: <a href="mailto:info@fazer.ee">info@fazer.ee</a>{' '}
        </li>
      </ol>
      <h3>OSALEMINE ja AUHINNAD</h3>
      <ol>
        <li>
          Kampaania info on kättesaadav aadressil{' '}
          <a href="http://www.fazer.ee">www.fazer.ee</a>{' '}
        </li>
        <li>
          Kampaania auhinna loosimises osalemiseks tuleb osta{' '}
          <span className="bold">ükskõik millisest kauplusest üle-Eesti</span>{' '}
          vähemalt üks kampaanias osalev{' '}
          <span className="bold">Fazer brändi toode</span> ja registreerida{' '}
          <span className="bold">ostutšeki number</span> veebilehel{' '}
          <a href="https://www.fazer.ee/">https://www.fazer.ee/</a> ajavahemikus{' '}
          <span className="bold">01.04.25 – 30.04.2025</span> (k.a).
        </li>
        <li>Kampaanias osalevad tooted on:</li>
        <table>
          <thead>
            <tr>
              <td className="bold">EAN</td>
              <td className="bold">Toote nimetus</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>4740103023594</td>
              <td>Fazer KODU pätsiLEIB 390 g</td>
            </tr>
            <tr>
              <td>4740103023624</td>
              <td>Fazer KODU röstLEIB 340 g</td>
            </tr>
            <tr>
              <td>4740103023570</td>
              <td>Fazer Street Food Seemnepala 240g</td>
            </tr>
          </tbody>
        </table>
        <li>
          Auhinnafondi koguväärtus on 3000 eurot.
          <span className="block-span">Auhinnafondis on kokku 1 auhind:</span>
          <span className="block-span">3000 € väärtuses IKEA kinkekaart</span>
        </li>
        <li>
          Kampaanias osaleja peab registreeritud ostutšeki alles hoidma
          kampaania lõpuni, et esitada see võimaliku võidu kättesaamiseks.
        </li>
        <li>Ühte ostutšekki saab kampaania jooksul registreerida ühe korra.</li>
        <li>
          Osaleja võib teha mitu registreerimist, kui ostab kampaanias osalevaid
          tooteid mitu korda ja registreerib iga kord uue ostutšeki numbri.
        </li>
        <h3>VÕITJA LOOSIMINE</h3>
        <li>
          Loosimised viiakse läbi loosimistarkvara stat.messenger.ee abil, mis
          võimaldab määrata loosimise täpse perioodi, auhindade kogused ning
          valib võitjad määratud perioodi jooksul registreerunud osalejate seast
          juhuse alusel. Auhinna loosimine toimub 06.05.2025. Loosimises
          osalevad kõik registreeringud, mis on tehtud ajavahemikus 01.04.2025
          kl 00:00:00 – 30.04.2025 kl 23:59:59.{' '}
        </li>
        <li>
          Loosimisel osalevad kõik kampaania perioodil vastavalt kampaania
          tingimustele üle-Eesti kauplustes oste sooritanud kliendid.{' '}
        </li>
        <li>
          Võitjaga võetakse korraldaja esindaja poolt ühendust
          registreerimisvormis märgitud e-posti või telefoni teel võidu
          kinnitamiseks ning auhinna kättetoimetamise täpsustamiseks. Võitja
          nimi avaldatakse veebilehel{' '}
          <a href="http://www.fazer.ee">www.fazer.ee</a> hiljemalt 3 tööpäeva
          jooksul pärast loosimise kuupäeva.{' '}
        </li>
        <li>
          Juhul, kui võitjaga ei ole õnnestunud ühendust saada hiljemalt 5
          tööpäeva jooksul on korraldajal õigus auhinda mitte väljastada ja
          loosida see välja uuele võitjale.
        </li>
        <h3>MUUD TINGIMUSED</h3>
        <li>
          Võidetud auhinna kättesaamisega seotud kulutusi ei kompenseerita,
          saadud auhinda ei asendata teise auhinnaga ega hüvitata selle
          maksumust rahas. Kampaania korraldaja võtab vastutuse kõigi
          auhindadega seotud seadusjärgsete maksude tasumise eest.
        </li>
        <li>
          Auhinna kättesaamiseks peab võitja esitama oma isikut tõendava
          dokumendi ning võidu toonud ostutšeki.
        </li>
        <li>
          Kampaanias ei saa osaleda selle korraldamisega seotud inimesed ja
          nende pereliikmed.
        </li>
        <li>
          Kampaania korraldaja ei kanna vastutust selle eest, kui auhinda
          eeskirjade mittejärgimise tõttu välja ei anta, kui kampaanias osaleja
          esitatud andmed on väärad või ebatäpsed või kui võitjatega ei ole
          võimalik ühendust võtta kampaania korraldajatest mittesõltuvatel
          põhjustel.
        </li>
        <li>
          Kõik kampaaniast tulenevad vaidlused lahendatakse vastavalt Eesti
          Vabariigi seadustele. Vääramatu jõu (force majeure) ilmnemisel on
          korraldajal õigus tarbijamäng ühepoolselt katkestada, teatades sellest
          meedia vahendusel.{' '}
        </li>
        <li>
          Kõik pretensioonid seoses kampaania korraldamise ja/või läbiviimisega
          tuleb saata kirjalikult kampaania korraldaja asukohta:{' '}
          <span className="bold">Fazer Eesti OÜ</span> (Reg. nr 10057691), Pärnu
          maantee 158/1, 11317 Tallinn, Kawe Ärikeskuse 5. korrus; telefon: +372
          650 2421; e-post: <a href="mailto:info@fazer.ee">info@fazer.ee</a> .
          Laekunud pretensioonidele vastatakse kirjalikult 2 nädala jooksul.
        </li>
        <li>
          Lisateavet kampaania kohta saab tööpäevadel{' '}
          <span className="bold">Fazer Eesti OÜ</span> (Reg. nr 10057691), Pärnu
          maantee 158/1, 11317 Tallinn, Kawe Ärikeskuse 5.korrus; telefon: +372
          650 2421; e-post: <a href="mailto:info@fazer.ee">info@fazer.ee</a>
        </li>
        <h3>ANDMETE TÖÖTLEMINE</h3>
        <li>
          Isikuandmete vastutav töötleja on Fazer Eesti OÜ, Pärnu mnt 158/1,
          11317 Tallinn. Kampaanias osalejatel on õigus saada lisainfot Fazer
          Eesti OÜ poolt isikuandmete töötlemise kohta. Selleks tutvu lähemalt
          Fazer Eesti OÜ privaatsustingimustega siin:{' '}
          <a href="https://www.fazergroup.com/privacy/">
            https://www.fazergroup.com/privacy/
          </a>{' '}
        </li>
        <li>Võitjate nimed on veebilehel üleval kuni 31.05.2025.</li>
        <li>Kogutud andmeid säilitatakse kuni 07.05.2025.</li>
      </ol>
    </div>
  );
};

export default Et;
